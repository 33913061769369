import React, { Component } from 'react';

class SocialMedia extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <a href='https://twitter.com/FRANKTRIGG' target='_blank' rel="noopener noreferrer"><div className="fa fa-twitter" style={{color:'rgba(0,172,237,1)',fontSize:'25px',padding:'15px'}}></div></a>
                    <a href='https://www.instagram.com/franktrigg' target='_blank' rel="noopener noreferrer"><div className="fa fa-instagram" style={{color:'rgba(251,57,88,1)',fontSize:'25px',padding:'15px'}}></div></a>
                    <a href='https://www.facebook.com/franktrigghost' target='_blank' rel="noopener noreferrer"><div className="fa fa-facebook" style={{color:'rgba(59,89,152,1)',fontSize:'25px',padding:'15px'}}></div></a>
                    <a href='https://www.imdb.com/name/nm1946095/' target='_blank' rel="noopener noreferrer"><div className="fa fa-imdb" style={{color:'rgba(243,206,19,1)',fontSize:'25px',padding:'15px'}}></div></a>
                    <a href='https://www.youtube.com/user/triggzinc' target='_blank' rel="noopener noreferrer"><div className="fa fa-youtube" style={{color:'rgba(255,0,0,1)',fontSize:'25px',padding:'15px'}}></div></a>
                </div>
            </React.Fragment>
        )
    }
}
export default SocialMedia;