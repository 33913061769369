import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideNav, { NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import SocialMedia from '../common/SocialMedia';

class Header extends Component {

    buttonNavButtonClicked = () => {
        console.log(document.getElementById("divNav").style.display);
        document.getElementById("divNav").style.display = 'block';
    }

    render() {
        return (
            <React.Fragment>
                { !this.props.isMobile ? 
                    // desktop
                    <div className="flexContainer">
                        <Link className="ThemeFont MenuButton btn FontSizeLevel2" to="/videos">videos</Link>
                        <a href='https://www.imdb.com/name/nm1946095/'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel2">imdb</div>
                        </a>
                        <a href='https://www.ufc.com/honors/ufc-hall-of-fame'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel2">hall of fame</div>
                        </a>
                        <a href='https://www.cameo.com/franktrigg'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel2">cameo</div>
                        </a>
                        <a href='mailto:jill@FrankTrigg.com'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel2">bookings</div>
                        </a>
                    </div>
                : 
                    //mobile - use side nav
                    <div>
                        <div>
                            <i className="fa fa-bars fa-2x" onClick={this.buttonNavButtonClicked.bind(this)} style={{padding:'20px',color:'rgba(51, 74, 116,0.99)'}}></i>
                        </div>
                        <div id="divNav" style={{display:'none'}}>
                            <SideNav expanded={true} style={{backgroundColor:'rgba(51, 74, 116,0.99)'}}
                                onToggle={(selected) => { 
                                    var currentState = document.getElementById("divNav").style.display;
                                    if(currentState=="block") {
                                        document.getElementById("divNav").style.display = 'none'
                                    }
                                    else {
                                        document.getElementById("divNav").style.display = 'block'
                                    }
                                }}
                                onSelect={(selected) => {
                                    document.getElementById("divNav").style.display = 'none';
                                    }
                                }>
                                <SideNav.Toggle/>
                                    <SideNav.Nav defaultSelected="home">
                                    <NavItem eventKey="video">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn  FontSizeLevel2" to="/videos">video</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="imdb">
                                        <NavText>
                                        <a href='mailto:jill@franktrigg.com'>
                                            <div className="ThemeFont MenuButton btn FontSizeLevel2" style={{color:'white'}}>imdb</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="halloffame">
                                        <NavText>
                                        <a href='mailto:jill@franktrigg.com'>
                                            <div className="ThemeFont MenuButton btn  FontSizeLevel2" style={{color:'white'}}>hall of fame</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="cameo">
                                        <NavText>
                                        <a href='mailto:jill@franktrigg.com'>
                                            <div className="ThemeFont MenuButton btn  FontSizeLevel2" style={{color:'white'}}>cameo</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="contact">
                                        <NavText>
                                        <a href='mailto:jill@franktrigg.com'>
                                            <div className="ThemeFont MenuButton btn  FontSizeLevel2" style={{color:'white'}}>bookings</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    <br/><br/>
                                    <NavItem eventKey="social">
                                        <NavText style={{marginLeft:'30px'}}>
                                            <SocialMedia isMobile={this.props.isMobile}/>
                                        </NavText>
                                    </NavItem>
                                </SideNav.Nav>
                            </SideNav>
                            <div>
                        </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default Header;