import React, { Component } from 'react';
import Header from '../common/Header';
import SocialMedia from '../common/SocialMedia';
import triggHero from '../content/trigg01.jpg';
import groundbased from '../content/groundbased.png';

class Home extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }
    
    render() {
        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div className='ThemeFont FontSizeLevel7' style={{color:'white',textAlign:'center'}}>
                        <span className='AccentColor'>Frank Trigg</span></div>                            
                    <br/>
                    <br/>
                    <table>
                        <tr>
                            <td>
                                <img className="ImageHero" src={triggHero} alt=""/>
                            </td>
                            <td valign='bottom'>
                                <div className='ThemeFont DesktopOnly' style={{color:'black',width:'450px',padding:'30px',textAlign:'center'}}>
                                    <span className='ThemeFont2 FontSizeLevel2'>Actor. Stunt Performer. SAG/AFTRA. UFC Hall of Fame class of 2015. COMMAND MMA referee & judge. Color Commentator. TV/Radio host</span>
                                    <br/>
                                    <center>
                                        <SocialMedia isMobile={this.props.isMobile}/>
                                        
                                        <br/><br/>
                                        <a href='https://ground-based.com' className='btn' style={{padding:'0px'}}>
                                                <img src={groundbased} width='250' alt=""/>
                                                <div className='ThemeFont2 FontSizeLevel1' style={{width:'250px',textAlign:'center'}}>
                                                    Buy 100% organic, Vegan Protein Powder<br/> & Plant-Based KETO Products
                                                    <br/>
                                                    Use promo code <b>Trigg20</b> at checkout for 20% off!
                                                </div>
                                            </a>
                                    </center>
                                </div> 
                            </td>
                        </tr>
                    </table>
                    <table>
                    <tbody>
                        <tr>
                        <td>
                            <div>
                                <div className='MobileOnly'>
                                    <div className='ThemeFont' style={{color:'black',width:'300px',padding:'30px',textAlign:'center'}}>
                                        <span className='ThemeFont2 FontSizeLevel2'>Actor. Stunt Performer. SAG/AFTRA. UFC Hall of Fame class of 2015. COMMAND MMA referee & judge. Color Commentator. TV/Radio host</span>
                                        <br/>
                                        <center>
                                            <SocialMedia isMobile={this.props.isMobile}/>
                                            <br/><br/>
                                            <a href='https://ground-based.com' className='btn' style={{padding:'0px'}}>
                                                <img src={groundbased} width='250' alt=""/>
                                                <div className='ThemeFont2 FontSizeLevel1' style={{width:'250px',textAlign:'center'}}>
                                                    Buy 100% organic, Vegan Protein Powder<br/> & Plant-Based KETO Products
                                                    <br/>
                                                    Use promo code <b>Trigg20</b> at checkout for 20% off!
                                                </div>
                                            </a>
                                        </center>
                                    </div>
                                </div>
                            </div>                            
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </center>
            </React.Fragment>
        )
    }
}
export default Home;