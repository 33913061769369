import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import YouTubeChannel from '../content/TriggYoutubeChannel.png';

class Videos extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
              return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>

                <center>
                    <br/><br/>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel4" style={{color:'black'}}>YouTube channel</div>
                    </div>
                    <br/>
                    <a href='https://www.youtube.com/user/triggzinc'>
                        <img src={YouTubeChannel} style={{width:'640px', textAlign:'center'}} />
                    </a>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel4" style={{color:'black'}}>acting reel</div>
                    </div>
                    <br/>
                    <iframe title="acting reel" src="https://player.vimeo.com/video/355836957" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    <p><a href="https://vimeo.com/355836957">Frank Trigg Acting Reel</a> from <a href="https://vimeo.com/user52932189">Justin Juntilla</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
                    <br/><br/><br/><br/>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel4" style={{color:'black'}}>stunt reel</div>
                    </div>
                    <br/>
                    <iframe title="tunt reel" src="https://player.vimeo.com/video/355838006" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    <p><a href="https://vimeo.com/355838006">Frank Trigg Stunt Reel</a> from <a href="https://vimeo.com/user52932189">Justin Juntilla</a> on <a href="https://vimeo.com">Vimeo</a>.</p>

                </center>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Videos;